import { AddressValidationSchemaType } from 'components/MyAccount/MyAccountAddressForm/AddressValidationSchema';
import { CountryCode } from 'constants/store';
import { Address, PostalCodeLocation } from 'types/Address';
import { Location } from 'types/Location';
import { Store } from 'types/Store';
import config from '../config';
import { checkNextDayDelivery, formatDate, isToday, isTomorrow } from './dateUtil';
import { destructurePostalCode } from './postalCodeUtil';
/**
 * Delivery address utility file.
 */

export const createDeliveryAddress = (values: AddressValidationSchemaType) => {
  const deliveryLocation = destructurePostalCode(values.delivery_location);

  const deliveryCountry = values?.country?.isocode || (config.defaultCountry as CountryCode);

  return {
    billingAddress: false,
    country: {
      isocode: deliveryCountry,
    },
    firstName: values?.firstName,
    lastName: values?.lastName,
    line1: values?.delivery_route,
    line2: values?.delivery_street_number,
    phone: values?.delivery_phone,
    pobox: values?.delivery_poBox || values?.poBox || '',
    postalCode: deliveryLocation?.postalCode,
    remarks: values?.remarks,
    shippingAddress: true,
    town: deliveryLocation?.town,
  };
};

export const createPostalCodeString = (location: Address | Location) =>
  [location?.postalCode, location?.town].filter(Boolean).join(' - ');

export type GetStoresWithHoursParams = {
  currentDate: string | Date;
  locale: string;
  stores?: Store[];
};
export function getStoresWithHours({ currentDate, locale, stores = [] }: GetStoresWithHoursParams) {
  const storeSummary: Store[] = [];
  const storeInput: Store[] = stores;

  storeInput.forEach((store) => {
    let storeInfo = {};
    if (store.name) {
      storeInfo = {
        ...storeInfo,
        displayName: store.displayName || store.name,
        name: store.name,
        ...(store?.postalCode && { postalCode: store.postalCode }),
      };
      if (store.firstAvailablePickupDate) {
        const tomorrowDelivery = checkNextDayDelivery(store.firstAvailablePickupDate, currentDate);
        if (tomorrowDelivery === 'nextHours') {
          const date = formatDate({ date: store.firstAvailablePickupDate, options: { locale, timeStyle: 'short' } });
          storeInfo = {
            ...storeInfo,
            deliveryDate: date,
          };
        } else if (tomorrowDelivery) {
          storeInfo = {
            ...storeInfo,
            deliveryDate: formatDate({
              date: store.firstAvailablePickupDate,
              options: {
                day: '2-digit',
                hour: '2-digit',
                hour12: false,
                locale,
                minute: '2-digit',
                month: '2-digit',
              },
            }),
            tomorrowDelivery: 'delivery_info_date_tomorrow',
          };
        } else {
          const date = formatDate({
            date: store.firstAvailablePickupDate,
            options: {
              day: '2-digit',
              hour: '2-digit',
              hour12: false,
              locale,
              minute: '2-digit',
              month: '2-digit',
            },
          });
          storeInfo = {
            ...storeInfo,
            deliveryDate: date,
          };
        }
      } else if (store.firstAvailablePickupInHours && store.firstAvailablePickupInHours === 1) {
        storeInfo = {
          ...storeInfo,
          deliveryHours: store.firstAvailablePickupInHours,
        };
      }
    }
    storeSummary.push(storeInfo);
  });
  return storeSummary;
}

/**
 * Function which returns the stores with hours array
 * @param {date} firstAvailablePickupDate - the firstAvailablePickupDate date
 * @param {number} firstAvailablePickupInHours - the firstAvailablePickupInHours date
 * @returns {object} the available hour message
 */
export const getStorePickupHours = (
  locale: string,
  firstAvailablePickupDate?: string,
  firstAvailablePickupInHours?: number,
) => {
  if (firstAvailablePickupDate) {
    if (isToday(firstAvailablePickupDate)) {
      return {
        deliveryDate: formatDate({ date: firstAvailablePickupDate, options: { locale, timeStyle: 'short' } }),
      };
    }

    if (isTomorrow(firstAvailablePickupDate)) {
      return {
        tomorrowDelivery: 'delivery_info_date_tomorrow',
      };
    }

    return {
      deliveryDate: formatDate({
        date: firstAvailablePickupDate,
        options: {
          day: '2-digit',
          hour: '2-digit',
          locale,
          minute: '2-digit',
          month: 'short',
        },
      }),
    };
  }

  if (firstAvailablePickupInHours) {
    return { deliveryHours: firstAvailablePickupInHours };
  }

  return null;
};

/**
 * Function which returns an array of postal code input options.
 * @param {Array} locations - the locations array.
 * @returns {Array} the array of postal code input options.
 */
export function createPostalCodeInputOptions(locations: PostalCodeLocation[] = []) {
  const inputOptions: { value: string }[] = [];

  if (locations?.length) {
    locations.forEach((location) => {
      if (location.postalCode && location.name) {
        inputOptions.push({
          value: `${location.postalCode} - ${location.name}`,
        });
      }
    });
  }

  return inputOptions;
}

export const getPickupInStoreHourMessage = (
  locale: string,
  firstAvailablePickupDate?: string,
  firstAvailablePickupInHours?: number,
) => {
  const storeInfo = getStorePickupHours(locale, firstAvailablePickupDate, firstAvailablePickupInHours);

  if (!storeInfo) return null;

  if (storeInfo.deliveryDate)
    return [{ id: 'delivery_pickup_in_store_number_of_days' }, { date: storeInfo.deliveryDate }];

  if (storeInfo.tomorrowDelivery) {
    return [{ id: storeInfo.tomorrowDelivery }];
  }

  if (storeInfo.deliveryHours)
    return [{ id: 'delivery_pickup_in_store_number_of_hours' }, { hours: storeInfo.deliveryHours }];

  return null; // Return null if no suitable delivery moment is found
};

export const getStreetNameWithNumber = (street?: string, streetNumber?: string, poBox?: string, locale?: string) => {
  if (locale === 'fr') {
    return [streetNumber, poBox, street].filter(Boolean).join(' ');
  }
  return [street, streetNumber, poBox].filter(Boolean).join(' ');
};
