import { ButtonProps } from 'components/Button/Button';
import { ForwardedRef, forwardRef } from 'react';
import { tv } from 'tailwind-variants';
import { cn } from 'utils/cn';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';

const radioButtonVariants = tv({
  base: 'p-0 flex border rounded-full size-5 items-center justify-center disabled:bg-accent-60 disabled:border-accent-60 disabled:text-white flex-shrink-0',
  compoundVariants: [{ className: 'bg-accent-60 border-accent-60 text-white', disabled: true }],
  variants: {
    disabled: {
      false: '',
      true: '',
    },
    selected: {
      false: 'bg-transparent border-accent-100',
      true: 'bg-primary-30 border-primary-30 text-white',
    },
  },
});

type RadioButtonProps = Omit<ButtonProps, 'size' | 'icon' | 'children'> & {
  disabled?: boolean;
  selected?: boolean;
  tag?: 'button' | 'div';
};

const RadioButton = forwardRef<HTMLButtonElement | HTMLDivElement, RadioButtonProps>(
  ({ className, disabled, selected = false, tag = 'button', type = 'button', ...props }, ref) => {
    if (tag === 'button') {
      return (
        <Button
          disabled={disabled}
          variant="naked"
          className={radioButtonVariants({ className, disabled, selected })}
          ref={ref as ForwardedRef<HTMLButtonElement>}
          type={type}
          {...props}
        >
          {selected && <Icon icon={{ name: 'check', styling: 'far' }} />}
        </Button>
      );
    }

    return (
      <div className={radioButtonVariants({ className, disabled, selected })} ref={ref as ForwardedRef<HTMLDivElement>}>
        {selected && <Icon icon={{ name: 'check', styling: 'far' }} />}
      </div>
    );
  },
);

type RadioCardProps = Omit<ButtonProps, 'size' | 'icon'> & {
  selected?: boolean;
};

const RadioCard = ({ children, className, disabled, onClick, selected = false, ...props }: RadioCardProps) => (
  <Button
    className={cn(
      'justify-start rounded border border-accent-40 px-3 py-2 disabled:bg-accent-40',
      onClick ? 'hover:border-primary-30 focus:border-primary-30' : '',
      selected ? 'border-primary-30 bg-primary-30/10' : 'bg-white',
      disabled ? 'bg-accent-60' : '',
      className,
    )}
    onClick={onClick}
    variant="naked"
    {...props}
  >
    {children}
  </Button>
);

export { RadioButton, RadioCard };
