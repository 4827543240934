import { ComponentProps, forwardRef } from 'react';
import { cn } from 'utils/cn';
import { Button } from '../Button/Button';

type Props = ComponentProps<typeof Button>;

const Pill = ({ children, className, textType = 'small', ...props }: Props) => (
  <Button
    variant="naked"
    textType={textType}
    className={cn(
      'hover:text-body rounded-pill border-transparent bg-accent-20 px-3 py-2 font-normal hover:border-accent-40 hover:bg-accent-40 focus-visible:border-accent-40',
      className,
    )}
    {...props}
  >
    {children}
  </Button>
);

const CheckboxPill = ({ active, children, textType = 'small', ...props }: Props & { active?: boolean }) => (
  <Pill
    textType={textType}
    icon={{ name: 'check', styling: 'far' }}
    iconClassName={cn(
      'rounded-sm border border-accent-100',
      active ? 'bg-primary-30 border-primary-30 text-white' : 'text-transparent',
    )}
    {...props}
  >
    {children}
  </Pill>
);

const ActionPill = forwardRef<HTMLButtonElement, Props>(({ children, className, ...props }, ref) => (
  <Pill ref={ref} className={cn('bg-accent-40 hover:border-accent-60', className)} {...props}>
    {children}
  </Pill>
));

export { ActionPill, CheckboxPill, Pill };
