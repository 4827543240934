import { cn } from 'utils/cn';

type Props = {
  className?: string;
};

const HorizontalDivider = ({ className }: Props) => <div className={cn('h-px w-full bg-accent-40', className)} />;

const VerticalDivider = ({ className }: Props) => <div className={cn('h-full w-px bg-accent-40', className)} />;

export { HorizontalDivider, VerticalDivider };
