import { useSelector } from 'hooks/useSelector';
import { Location } from 'types/Location';
import { destructurePostalCode } from 'utils/postalCodeUtil';
import useAuthResolver from './useAuthResolver';

const useLocation = (): Location | undefined => {
  const { user } = useAuthResolver();

  const geoPostalCode = useSelector((state) => destructurePostalCode(state?.checkout?.postalCode));

  if (geoPostalCode) {
    return geoPostalCode;
  }

  if (user?.defaultAddress) {
    return {
      country: user.defaultAddress.country,
      postalCode: user.defaultAddress.postalCode,
      town: user.defaultAddress.town,
    };
  }

  return undefined;
};

export default useLocation;
