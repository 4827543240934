import NextLink from 'next/link';
import { ComponentProps, forwardRef, MouseEventHandler, ReactNode } from 'react';

import { cn } from 'utils/cn';
import { isExternalUrl } from 'utils/urlUtil';
import { Text, textVariants, type FontType } from '../Text/Text';

export interface LinkProps extends ComponentProps<typeof NextLink> {
  newTab?: boolean;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, className, href, newTab, onClick, ...nextLinkProps }, ref) => {
    const isExternal = isExternalUrl(href);
    const openInNewTab = newTab || isExternal;

    return (
      <NextLink
        rel={openInNewTab ? 'noopener noreferrer' : ''}
        target={openInNewTab ? '_blank' : '_self'}
        className={className}
        href={href}
        ref={ref}
        onClick={onClick}
        {...nextLinkProps}
      >
        {children}
      </NextLink>
    );
  },
);

Link.displayName = 'Link';

const OptionalLink = forwardRef<HTMLAnchorElement, Omit<LinkProps, 'href'> & { href?: LinkProps['href'] }>(
  ({ children, className, href, ...props }, ref) => {
    if (!href) return <div className={className}>{children}</div>;

    return (
      <Link {...props} className={className} href={href} ref={ref}>
        {children}
      </Link>
    );
  },
);

OptionalLink.displayName = 'OptionalLink';

type TextLinkBaseProps = {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  type?: FontType;
};

export type TextLinkWithHrefProps = TextLinkBaseProps & {
  href: LinkProps['href'];
  locale?: string;
  newTab?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  prefetch?: LinkProps['prefetch'];
};

type TextLinkWithoutHrefProps = TextLinkBaseProps & {
  onClick?: MouseEventHandler<HTMLDivElement | HTMLSpanElement>;
};

export type TextLinkProps = TextLinkWithHrefProps | TextLinkWithoutHrefProps;

// TODO: Style this component
const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  ({ children, className, type = 'body', ...props }, ref) => {
    if ('href' in props) {
      const { href, locale, newTab, onClick, prefetch, style } = props;

      return (
        <Link
          newTab={newTab}
          onClick={onClick}
          className={cn(
            textVariants[type],
            onClick ? 'cursor-pointer' : '',
            'text-link-base no-underline hover:text-link-hover',
            className,
          )}
          locale={locale}
          href={href}
          ref={ref}
          prefetch={prefetch}
          style={style}
        >
          {children}
        </Link>
      );
    }

    const { onClick, style } = props;

    return (
      <Text
        className={cn(
          'focus-border text-link-base no-underline hover:text-link-hover',
          onClick ? 'cursor-pointer' : '',
          className,
        )}
        ref={ref}
        type={type}
        onClick={onClick}
        style={style}
      >
        {children}
      </Text>
    );
  },
);

TextLink.displayName = 'TextLink';

export { Link, OptionalLink, TextLink };
