import DeliveryPostalCodeSelect from 'components/DeliveryPostalCodeSelect/DeliveryPostalCodeSelect';
import FormError from 'components/Form/FormError/FormError';
import Loader from 'components/Layout/Loader/Loader';
import Typography from 'components/Typography/Typography';
import { usePickupLocationsByProductCode, useStoresByLocation } from 'features/stores/queries';
import useLocation from 'hooks/useLocation';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useIntl } from 'react-intl';
import { Country } from 'types/Address';
import { Location } from 'types/Location';
import { Store } from 'types/Store';
import { cn } from 'utils/cn';
import { createPostalCodeString } from 'utils/deliveryAddressUtil';
import { destructurePostalCode } from 'utils/postalCodeUtil';
import PickupInStoreItem from './PickupInStoreItem/PickupInStoreItem';

interface PickupInStoreDetailProps {
  className?: string;
  getPickUpStores?: () => void;
  isFavoriteStoreSelect?: boolean;
  onDeliverySubmit?: () => void;
  onFavouriteButtonClick?: (store: Store) => void;
  onSelectDeliveryMethodDetails?: () => void;
  onSelectStore?: (store: Store) => void;
  productCode?: string;
  showOpeningHours?: boolean;
}

const PickupInStoreDetail = ({
  className,
  onFavouriteButtonClick,
  onSelectStore,
  productCode,
  showOpeningHours,
}: PickupInStoreDetailProps) => {
  const { formatMessage } = useIntl();

  const locationFromDefaultAddress = useLocation();

  const [country, setCountry] = useState<Country>();
  const [locationObject, setLocationObject] = useState<Location>();

  const location = locationObject ?? locationFromDefaultAddress;

  const locationString = location ? createPostalCodeString(location) : undefined;

  const { data: storesByLocation, isLoading: isLoadingStoresByLocation } = useStoresByLocation({
    countryCode: country?.isocode,
    enabled: !productCode,
    fields: 'FAVOURITE_STORE',
    location: location?.town ?? '',
    pageSize: 5,
    postalCode: location?.postalCode ?? '',
  });

  const { data: pickupLocations, isFetching: isLoadingSstoresByProductCode } = usePickupLocationsByProductCode({
    countryCode: country?.isocode,
    enabled: !!productCode,
    location: location?.town ?? '',
    postalCode: location?.postalCode ?? '',
    productCode: productCode ?? '',
  });

  const stores = productCode ? pickupLocations : storesByLocation;
  const isLoading = productCode ? isLoadingSstoresByProductCode : isLoadingStoresByLocation;

  const onPostalCodeSubmit = (location: string) => {
    setLocationObject(destructurePostalCode(location));
  };

  return (
    <div className={cn('relative', className)}>
      <Loader position="absolute" show={isLoading} />

      <Typography tag="h1" type="h3">
        {formatMessage({
          id: 'delivery_pickup_in_store_select_store_minimal',
        })}
      </Typography>

      <Form
        initialValues={{ location: locationString }}
        mutators={{
          updatePostalCode: (value, state, utils) => {
            const location = value[0];
            utils.changeValue(state, `location`, () => location);
            onPostalCodeSubmit(location);
          },
        }}
        onSubmit={(values) => onPostalCodeSubmit(values.location)}
        render={({ form, handleSubmit }) => (
          <form id={'PUIS_form'} onSubmit={handleSubmit}>
            <FormError />

            <DeliveryPostalCodeSelect
              id="location"
              label={formatMessage({
                id: 'my_account_address_form_location',
              })}
              name="location"
              onSubmitPostalCode={form.mutators.updatePostalCode}
              withIcons
              country={country}
              setCountry={setCountry}
            />
          </form>
        )}
      />

      {stores?.map((store, index) => (
        <PickupInStoreItem
          key={index}
          isFavoriteStoreSelect={!productCode}
          onSelectStore={(store) => onSelectStore?.(store)}
          onFavouriteButtonClick={(store) => onFavouriteButtonClick?.(store)}
          store={store}
          town={location?.town}
          showOpeningHours={showOpeningHours}
        />
      ))}
    </div>
  );
};

export default PickupInStoreDetail;
